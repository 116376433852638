import React from 'react'
import './AuthPage.scss'
import { Form, Input, Button, Checkbox, Card } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../store/Actions/AuthActions'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { RootState } from '../../store/Reducers/rootReducer'


type AuthPageProps = {}

const AuthPage: React.FC<AuthPageProps> = () => {

    const isSending = useSelector<RootState>(state => state.auth.loader) as boolean
    const errorMessage = useSelector<RootState>(state => state.auth.errorMessage) as string
    const dispatch = useDispatch()

    const onFinish = (values: any) => {
        dispatch(login(values))
    }

    return (
        <div className="bf-auth">
            <div className="container">

                <div className="site-card-border-less-wrapper">
                    <Card title="Авторизация" bordered={false} className="bf-auth-form">
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="E-Mail Address"
                                name="email"
                                rules={[
                                    { required: true, message: 'Введите E-mail' }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Введите пароль' },
                                    { min: 6, message: 'Минимум 6 символов' }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            {
                                errorMessage && <div className="bf-auth-error-message">
                                    <p>{errorMessage}</p>
                                </div>
                            }

                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large">
                                    Login
                                </Button>

                                {
                                    isSending && <Spin indicator={<LoadingOutlined style={{ fontSize: 32, marginLeft: 20 }} spin />} />
                                }

                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AuthPage