import { Draggable, Droppable } from "react-beautiful-dnd"
import { ScreenItem } from "../../../types/responseTypes"
import SliderItem from "../../SliderItem/SliderItem"


type MediaItemsProps = {
    items: ScreenItem[]
    onDeleteItem: (item: ScreenItem) => void
}

const MediaItems: React.FC<MediaItemsProps> = ({ items, onDeleteItem }) => {

    return (
        <Droppable
            droppableId="slider-items"
        >
            {(provided) => (
                <div
                    className="media-slides__body"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {
                        items.map((item, index) => {
                            if (item.catalog === 'p_del') return null
                            return (
                                <Draggable draggableId={item.id + ''} index={index} key={item.id}>
                                    {(provided) => (
                                        <SliderItem
                                            item={item}
                                            onDeleteItem={onDeleteItem}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        />
                                    )}
                                </Draggable>
                            )
                        })
                    }
                    { provided.placeholder }
                </div>
            )}
        </Droppable>
    )
}

export default MediaItems