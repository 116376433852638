import { AllAuthActions } from '../Actions/AuthActions'
import { AUTH_ERROR_MESSAGE, AUTH_SET_LOADER, AUTH_SET_LOGOUT_LINK, AUTH_SET_STATUS } from '../Actions/ActionTypes'

export type AuthState = {
    isAuth: boolean | null
    link_logout: string
    errorMessage: string
    loader: boolean
}

const authInitialState: AuthState = {
    isAuth: null,
    link_logout: '',
    errorMessage: '',
    loader: false,
}

export const authReducer = (state = authInitialState, action: AllAuthActions) => {
    switch(action.type) {
        case(AUTH_SET_STATUS): {
            return {
                ...state,
                isAuth: action.payload
            }
        }
        case(AUTH_SET_LOGOUT_LINK): {
            return {
                ...state,
                link_logout: action.payload
            }
        }
        case(AUTH_ERROR_MESSAGE): {
            return {
                ...state,
                errorMessage: action.payload
            }
        }
        case(AUTH_SET_LOADER): {
            return {
                ...state,
                loader: action.payload
            }
        }
        default: {
            return state
        }
    }
}