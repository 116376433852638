import { UserOutlined, BlockOutlined, PictureOutlined,
    CloudUploadOutlined, BarChartOutlined, FieldTimeOutlined,
    TeamOutlined, SettingOutlined } from '@ant-design/icons'
import Cron from '../Pages/Cron/Cron'
import Media from '../Pages/Media/Media'
import Process from '../Pages/Process/Process'
import Profile from '../Pages/Profile/Profile'
import FrameReporting from '../Pages/reporting/FrameReporting/FrameReporting'
import ProfileSetting from '../Pages/settings/ProfileSetting/ProfileSetting'
import Roles from '../Pages/settings/Roles/Roles'
import UploadPage from '../Pages/UploadPage/UploadPage'
import AllUsers from '../Pages/users/AllUsers/AllUsers'
import RegisterUser from '../Pages/users/RegisterUser/RegisterUser'

import { UserRoutes } from '../types/routesType'

export const routes: UserRoutes = [
    {
        path: '/',
        name: 'Профиль',
        rule: 'profile',
        component: Profile,
        icon: UserOutlined
    },
    {
        path: '/bp',
        name: 'Бизнес процессы',
        rule: 'process',
        component: Process,
        icon: BlockOutlined
    },
    {
        path: '/media',
        name: 'Экраны',
        rule: 'mediaclub',
        component: Media,
        icon: PictureOutlined
    },
    {
        path: '/file',
        name: 'Тестовые загрузки файлов',
        rule: 'testfile',
        component: UploadPage,
        icon: CloudUploadOutlined
    },
    {
        path: '/reporting',
        name: 'Отчеты',
        rule: 'report',
        icon: BarChartOutlined,
        component: null,
        children: [
            {
                path: '',
                component: FrameReporting,
                name: 'Просроченные задачи Pyrus',
                rule: 'duePyrus'
            }
        ]
    },
    {
        path: '/tasks',
        name: 'Задачи Cron',
        rule: 'cron',
        component: Cron,
        icon: FieldTimeOutlined
    },
    {
        path: '/users',
        name: 'Пользователи',
        rule: 'users',
        icon: TeamOutlined,
        component: null,
        children: [
            {
                path: '',
                component: AllUsers,
                name: "Все пользователи",
                rule: "view"
            },
            {
                path: 'new',
                component: RegisterUser,
                name: "Создать нового",
                rule: "view"
            }
        ]
    },
    {
        path: '/settings',
        name: 'Настройки',
        rule: 'settings',
        component: null,
        icon: SettingOutlined,
        children: [
            {
                path: '',
                component: ProfileSetting,
                name: "Профиль",
                rule: "view"
            },
            {
                path: 'rules',
                component: Roles,
                name: 'Роли',
                rule: 'rules',
                // children: [
                //     {
                //         path: 'ss',
                //         component: Profile,
                //         name: "Профиль",
                //         rule: "ss"
                //     },
                //     {
                //         path: 'crone',
                //         component: Cron,
                //         name: "Glory",
                //         rule: "crone"
                //     },
                // ]
            }
        ]
    }
]