import axios from 'axios';
import { AuthResponse } from './../types/responseTypes';
import { ACCESS_TOKEN_COOKIE_NAME, AUTH_REFRESH_URL, REFRESH_TOKEN_COOKIE_NAME } from './../config';
import { deleteTokensFromSession, getTokensFromSession, setTokensToSession } from './session';

export const getAnyRefresh = () => {
    const cookieRefreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME)
    const sessionRefreshToken = window.sessionStorage.getItem(REFRESH_TOKEN_COOKIE_NAME)
    return sessionRefreshToken || cookieRefreshToken
}

export const freshAccessToken = async () => {
    try {
        const accessFromCookie = getCookie(ACCESS_TOKEN_COOKIE_NAME)
        const refreshFromCookie = getCookie(REFRESH_TOKEN_COOKIE_NAME)

        const {
            access_token: accessFromSession,
            refresh_token: refreshFromSession
        } = getTokensFromSession()

        if (accessFromCookie || refreshFromCookie) {
            // if user choosen Remember me
            console.log('throw cookie');

            if (accessFromCookie) return accessFromCookie

            if (refreshFromCookie) {
                const refreshBody = { refresh_token: refreshFromCookie }
                const res = await axios.post(AUTH_REFRESH_URL, refreshBody)
                const data = res.data as AuthResponse
                if (data) {
                    setTokensToCookie(data)
                    return data.access_token
                }
                deleteCookie(refreshFromCookie)
            }
        } else if (accessFromSession || refreshFromSession) {
            // if user didn't choose Remember me
            console.log('throw storage');

            if (accessFromSession) return accessFromSession

            if (refreshFromSession) {
                const refreshBody = { refresh_token: refreshFromSession }
                const res = await axios.post(AUTH_REFRESH_URL, refreshBody)
                const data = res.data as AuthResponse
                if (data) {
                    setTokensToSession(data)
                    return data.access_token
                }
                deleteTokensFromSession()
            }
        }
        document.location.reload()
    } catch (e) {
        console.log('get fresh access token error', e)
        deleteCookie(ACCESS_TOKEN_COOKIE_NAME)
        deleteCookie(REFRESH_TOKEN_COOKIE_NAME)
        deleteTokensFromSession()
        document.location.reload()
    }
}

export function getCookie(name: string): string | null {
    let result: string | null = null
    const cookie = document.cookie
    const cookieProp = cookie.split('; ')
    cookieProp.forEach(cookiePair => {
        const [key, value] = cookiePair.split('=')
        if (key === name) {
            result = value
        }
    })
    return result
}

export function setCookie(name: string, value: string, maxAge: number = 86398): void {
    document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`
}

export function deleteCookie(name: string): void {
    console.log('COOKIE DELETED', Date.now());
    document.cookie = `${name}=; path=/; expires=0; max-age=0`
}

export function setTokensToCookie(data: AuthResponse) {
    setCookie(ACCESS_TOKEN_COOKIE_NAME, data.access_token, data.expires_in)
    setCookie(REFRESH_TOKEN_COOKIE_NAME, data.refresh_token, data.refresh_expires_in)
}

export function deleteTokensFromCookie() {
    deleteCookie(ACCESS_TOKEN_COOKIE_NAME)
    deleteCookie(REFRESH_TOKEN_COOKIE_NAME)
}