import { UserRole } from "../../types/rolesType"
import { UserRoutes } from "../../types/routesType"
import { APP_INITIAL_USER, APP_RESET } from "../Actions/ActionTypes"
import { AllAppActions } from "../Actions/AppActions"
import { userStartRole } from '../../data/static'
import { UserType } from "../../types/responseTypes"

export type AppState = {
    routes: UserRoutes | null
    user: UserType | null
    user_role: UserRole
}

const appInitialState: AppState = {
    routes: null,
    user: null,
    user_role: userStartRole,
}

export const appReducer = (state = appInitialState, action: AllAppActions): AppState => {
    switch(action.type) {
        case(APP_INITIAL_USER): {
            const p = action.payload
            return {
                ...state,
                routes: p.routes,
                user: p.user,
            }
        }
        case(APP_RESET): {
            return {
                ...state,
                ...appInitialState
            }
        }
        default: {
            return state
        }
    }
}
