import React, { useState } from 'react'
import MainMenu from '../../Navbar/MainMenu/MainMenu'
import Navbar from '../../Navbar/Navbar'
import './MenuLayout.scss'

type MenuLayoutProps = {}

const MenuLayout: React.FC<MenuLayoutProps> = (props) => {

    const [isShowMenu, setShowMenu] = useState<boolean>(false)

    const hideMainMenu = () => {
        setShowMenu(false)
    }

    return (
        <>
            <Navbar toggleMenu={() => setShowMenu(!isShowMenu)}/>
            <MainMenu
                isShow={isShowMenu}
                hideMainMenu={hideMainMenu}
            />
            <section className="layout-offset">
                { props.children }
            </section>
        </>
    )
}

export default MenuLayout