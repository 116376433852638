import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import './CaruselMedia.scss'
import axios from 'axios'
import { MediaScreensResponse, ScreenItem } from '../../types/responseTypes'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { CAROUSEL_BASE_URL } from '../../config'

interface CaruselMediaProps extends RouteComponentProps { }

type RouterParams = {
    catalog: string
}

const CaruselMedia: React.FC<CaruselMediaProps> = ({ match }) => {
    const params = match.params as RouterParams

    const [sliderInterval, setSliderInterval] = useState<number>(10)
    const [items, setItems] = useState<ScreenItem[]>([])

    const [isLoading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const init = async () => {
        try {
            setLoading(true)
            const url = `${CAROUSEL_BASE_URL}/${params.catalog}`
            const res = await axios.get(url)
            console.log(res)
            const data = res.data as MediaScreensResponse
            if (data) {
                setErrorMessage('')
                setItems(data.files)
                setSliderInterval(+data.interval)
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
            setErrorMessage(e.message)
        }
    }

    useEffect(() => {
        init()
        const intervalId = setInterval(init, 3600000)
        return () => { clearInterval(intervalId) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (
            <section className="bf-carusel">
                <div className="bf-carusel__loader">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                </div>
            </section>
        )
    }

    return (
        <section className="bf-carusel">
            {
                errorMessage
                    ? <div className="bf-carusel__error">
                        <p>Возникла ошибка: {errorMessage}</p>
                    </div>
                    : <Carousel
                        interval={sliderInterval * 1000}
                        transitionTime={150}

                        autoPlay
                        showThumbs={false}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                        infiniteLoop={true}
                    >
                        {
                            items.map(it => (
                                <div
                                    className="bf-carusel__item"
                                    key={Math.random()}
                                    style={{
                                        backgroundImage: `url(${it.img})`
                                    }}
                                >
                                </div>
                            ))
                        }
                    </Carousel>
            }
        </section>
    )
}

export default CaruselMedia

