import React from 'react'
import { Form, Input, Select } from 'antd'
import './Profile.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/Reducers/rootReducer'
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout'
const { Option } = Select

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = (props) => {

    const userName = useSelector<RootState>(state => state.app.user?.name) as string
    const userEmail = useSelector<RootState>(state => state.app.user?.email) as string
    const userRoleId = useSelector<RootState>(state => state.app.user_role?.id) as string
    const userRoleName = useSelector<RootState>(state => state.app.user_role?.name) as string

    return (
        <MenuLayout>
            <Form
                name="prof"
                initialValues={{
                    username: userName,
                    useremail: userEmail
                }}
            >
                <Form.Item
                    label="Имя"
                    name="username"
                    className="profile-input"
                >
                    <Input disabled={true} />
                </Form.Item>

                <Form.Item
                    label="E-mail"
                    name="useremail"
                    className="profile-input"
                >
                    <Input disabled={true} />
                </Form.Item>

                <p>Роль:</p>
                <Select defaultValue={userRoleId} disabled={true} className="profile-select">
                    <Option value={userRoleId} disabled>
                        {userRoleName}
                    </Option>
                </Select>
            </Form>
        </MenuLayout>
    )
}

export default Profile