import React from 'react'
import './ProfileSetting.scss'
import MenuLayout from '../../../Components/Layouts/MenuLayout/MenuLayout'

interface ProfileSettingProps {}

const ProfileSetting: React.FC<ProfileSettingProps> = () => {

    return (
        <MenuLayout>
            <h1>ProfileSetting page</h1>
        </MenuLayout>
    )
}

export default ProfileSetting