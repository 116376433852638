import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './Loader.scss'

type LoaderProps = {
    classes?: string[]
}

const Loader: React.FC<LoaderProps> = ({ classes }) => {

    const antIcon = <LoadingOutlined spin />
    let loaderClasses = ['bf-loader']

    if (classes) {
        loaderClasses = loaderClasses.concat(classes)
    }

    return (
        <div className={loaderClasses.join(' ')}>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default Loader