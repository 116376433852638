import axios from "axios"
import { Dispatch } from "react"
import { AUTH_LOGIN_URL, AUTH_LOGOUT_URL, AUTH_REFRESH_URL, REFRESH_TOKEN_COOKIE_NAME, TOKEN_TYPE } from "../../config"
import { ReturnActions } from "../../types/actionsType"
import { AuthFormFields } from "../../types/dataType"
import { AuthResponse } from "../../types/responseTypes"
import { deleteTokensFromCookie, freshAccessToken, getAnyRefresh, getCookie, setTokensToCookie } from "../../utils/cookie"
import { deleteTokensFromSession, setTokensToSession } from "../../utils/session"
import { AUTH_LOGOUT, AUTH_SET_STATUS, AUTH_SET_LOGOUT_LINK, AUTH_ERROR_MESSAGE, AUTH_SET_LOADER } from "./ActionTypes"
import { AllAppActions, appActions } from "./AppActions"


export const checkAuth = () => {
    return async (dispatch: Dispatch<AllAuthActions>) => {
        try {
            const cookieRefreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME)
            const sessionRefreshToken = window.sessionStorage.getItem(REFRESH_TOKEN_COOKIE_NAME)
            const refreshToken = sessionRefreshToken || cookieRefreshToken
            if (refreshToken) {
                // if max-age of a token is "session". setCookie with shouldn't Remember argument
                const res = await axios.post(AUTH_REFRESH_URL, { refresh_token: refreshToken })
                console.log('checkAuth res', res)

                const data = res.data as AuthResponse
                if (data.access_token) {
                    if (sessionRefreshToken) {
                        //if old refresh token was in window.sessionStoreage (user doesn't choose 'remember me')
                        setTokensToSession(data)
                    } else {
                        setTokensToCookie(data)
                    }
                    dispatch(authActions.setAuth(true))
                    return
                }
            }
            deleteTokensFromCookie()
            deleteTokensFromSession()
            dispatch(authActions.setAuth(false))
        } catch (e) {
            console.log(e)
            deleteTokensFromCookie()
            deleteTokensFromSession()
            dispatch(authActions.setAuth(false))
        }
    }
}

export const login = (loginFields: AuthFormFields) => {
    return async (dispatch: Dispatch<AllAuthActions>) => {
        try {
            dispatch(authActions.setLoader(true))

            const loginResp = await axios.post(AUTH_LOGIN_URL, {
                email: loginFields.email,
                password: loginFields.password
            })
            dispatch(authActions.setLoader(false))
            const loginData = loginResp.data as AuthResponse

            if (loginData) {
                if (loginFields.remember) {
                    setTokensToCookie(loginData)
                } else {
                    setTokensToSession(loginData)
                }
                dispatch(authActions.setAuth(true))
                return
            }
            dispatch(authActions.setErrorMessage('Неверный логин или пароль'))
        } catch (e) {
            console.log(e)
            dispatch(authActions.setLoader(false))
            dispatch(authActions.setErrorMessage('Неверный логин или пароль'))
        }
    }
}

export const logout = () => {
    return async (dispatch: Dispatch<AllAuthActions | AllAppActions>) => {
        try {
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const refreshToken = getAnyRefresh()
            const body = { refresh_token: refreshToken }
            await axios.post(AUTH_LOGOUT_URL, body, option)
        } catch (e) {
            console.log(e)
        } finally {
            deleteTokensFromCookie()
            deleteTokensFromSession()
            dispatch(authActions.logoutUser())
            dispatch(authActions.setAuth(false))
            dispatch(appActions.resetAppData())
        }

    }
}


export const authActions = {
    setAuth(payload: boolean) {
        return {
            type: AUTH_SET_STATUS,
            payload
        } as const
    },
    setLogoutLink(payload: string) {
        return {
            type: AUTH_SET_LOGOUT_LINK,
            payload
        } as const
    },
    setErrorMessage(payload: string) {
        return {
            type: AUTH_ERROR_MESSAGE,
            payload
        } as const
    },
    setLoader(payload: boolean) {
        return {
            type: AUTH_SET_LOADER,
            payload
        } as const
    },
    logoutUser() {
        return {
            type: AUTH_LOGOUT
        } as const
    },
}

export type AllAuthActions = ReturnActions<typeof authActions>