import React from 'react'
import './Process.scss'
import { Tabs } from 'antd'
import NewProcess from '../../Components/NewProcess/NewProcess'
import DataProcess from '../../Components/DataProcess/DataProcess'
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout'
const { TabPane } = Tabs

interface ProcessProps {}

const Process: React.FC<ProcessProps> = () => {

    return (
        <MenuLayout>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Новый процесс" key="1">
                    <NewProcess />
                </TabPane>

                <TabPane tab="Все процессы" key="2"></TabPane>

                <TabPane tab="Получить данные" key="3">
                    <DataProcess />
                </TabPane>
            </Tabs>
        </MenuLayout>
    )
}

export default Process

