import React from 'react'
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { UserChildRoute, UserRoutes } from "../types/routesType";
import SubMenu from 'antd/lib/menu/SubMenu';

export function renderMenu(routes: UserRoutes): JSX.Element[] {

    const menu: JSX.Element[] = []
    routes.map(route => {
        const Icon = route.icon

        if (route.children) {
            if (route.children.length === 0) return null
            if (route.children.length > 0) {
                const childTabs = renderChild(route.children, route.path)
                menu.push(
                    <SubMenu key={route.path} icon={<Icon />} title={route.name}>
                        {childTabs}
                    </SubMenu>
                )
                return null
            }
            return null
        }

        menu.push(
            <Menu.Item key={route.path}>
                <NavLink to={route.path} className="main-menu-link" exact>
                    <Icon />
                    <span className="main-menu-link__text">{route.name}</span>
                </NavLink>
            </Menu.Item>
        )
        return null
    })
    return menu
}

const renderChild = (routes: UserChildRoute[], parentPath: string): Array<JSX.Element | null> => {
    return routes.map(route => {
        const pagePath = `${parentPath}/${route.path}`

        if (route.children && route.children.length > 0) {
            const links = renderChild(route.children, pagePath)
            return (
                <SubMenu key={pagePath} title={route.name}>
                    {links}
                </SubMenu>
            )
        }
        return (
            <Menu.Item key={pagePath}>
                <NavLink
                    to={pagePath}
                    className="main-menu-link"
                    exact
                >
                    <span className="main-menu-link__text">{route.name}</span>
                </NavLink>
            </Menu.Item>
        )
    })
}