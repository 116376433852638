import { ProcessForm } from './../types/dataType'


export function getProcessForm(): ProcessForm {
    return {
        id: 0,
        state: 'wait',
        title: 'Новая форма',
        _key: Math.random(),
    }
}

export function getBase64(img: File, callback: (url: string | ArrayBuffer | null) => unknown) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}