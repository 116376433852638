export const TOKEN_TYPE = 'Bearer'
export const ACCESS_TOKEN_COOKIE_NAME = 'bfnotion-access'
export const REFRESH_TOKEN_COOKIE_NAME = 'bfnotion-refresh'

export const AUTH_LOGIN_URL = 'https://bfnotion.ru/login'
export const AUTH_REFRESH_URL = 'https://bfnotion.ru/refresh'
export const AUTH_LOGOUT_URL = 'https://bfnotion.ru/logout'

export const APP_USER_PROFILE = 'https://bfnotion.ru/user-profile'

export const MEDIA_GET_ITEMS = 'https://bfnotion.ru/site/media/p/get'
export const MEDIA_ADD_ITEM = 'https://bfnotion.ru/site/media/p/upload'
export const MEDIA_DELETE_ITEM = 'https://bfnotion.ru/site/media/p/delete'
export const MEDIA_SEND_DATA = 'https://bfnotion.ru/site/media/p/save'

export const CAROUSEL_BASE_URL = 'https://bfnotion.ru/site/media'

/// other functions, old authorization version 401 error
export const PYRUS_GET_DATA = 'https://bfnotion.ru/pyrus/get/data'
export const PYRUS_SEND_DATA = 'https://bfnotion.ru/pyrus/post/data'
export const PYRUS_FORM = 'https://bfnotion.ru/pyrus/form'

export const DROP_UPLOAD = 'https://bfnotion.ru/drop/upload'
export const GOOGLE_UPLOAD = 'https://bfnotion.ru/google/upload'

export const REGISTER_USER = 'https://bfnotion.ru/site/register'
export const REGISTER_USER_VALID_EMAIL = 'https://bfnotion.ru/site/valid/email'
export const REGISTER_USER_RULES = 'https://bfnotion.ru/site/rules/all'