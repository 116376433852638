import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, notification } from 'antd'
import './RegisterUser.scss'
import axios from 'axios'
import { Role, RoleResponse } from '../../../types/responseTypes'
import { RuleObject } from 'antd/lib/form'
import MenuLayout from '../../../Components/Layouts/MenuLayout/MenuLayout'
import { REGISTER_USER, REGISTER_USER_RULES, REGISTER_USER_VALID_EMAIL, TOKEN_TYPE } from '../../../config'
import { freshAccessToken } from '../../../utils/cookie'

interface RegisterUserProps { }

type UserRegisterFields = {
    name: string
    email: string
    password: string
    password_confirmation: string
    role: number
}

const RegisterUser: React.FC<RegisterUserProps> = () => {

    const [roles, setRoles] = useState<Role[]>([])
    const [totalError, setTotalError] = useState<string>('')
    const [form] = Form.useForm()

    const onReset = () => {
        form.resetFields()
        setTotalError('')
    }

    const onFinish = async (values: UserRegisterFields) => {
        try {
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}`, } }
            const res = await axios.post(REGISTER_USER, values, option)
            const error = res.data.error
            if (error) {
                setTotalError(res.data.error_message)
            } else {
                setTotalError('')
                notification.success({
                    message: 'Регистрация',
                    description: 'Пользователь успешно зарегистрирован',
                    placement: 'bottomRight',
                    duration: 10,
                })
            }
            console.log(res)
        } catch (e) {
            console.log(e)
        }
    }

    const emailAsyncValidate = async (_: RuleObject, email: string) => {
        const access = await freshAccessToken()
        const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}`, } }
        const res = await axios.post(REGISTER_USER_VALID_EMAIL, { email }, option)
        return res.data.done ? Promise.resolve() : Promise.reject(new Error(res.data))
    }

    useEffect(() => {
        (async function () {
            try {
                const access = await freshAccessToken()
                const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}`, } }
                const res = await axios.get(REGISTER_USER_RULES, option)
                const data = res.data as RoleResponse
                console.log(res)
                setRoles(data.roles)
            } catch (e) {
                console.log(e)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MenuLayout>
            <Form
                form={form}
                name="user-register"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Имя"
                    name="name"
                    className='reg-label'
                    rules={[
                        { required: true, message: 'Введите имя' },
                        { min: 6, message: 'Имя должно содержать не менее 6 символов' },
                        { max: 255, message: 'Имя должно содержать не болнн 255 символов' }
                    ]}
                >
                    <Input className="reg-input-text" placeholder="ФИО" />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    className='reg-label'
                    rules={[
                        { required: true, message: 'Email обязателен для заполнения' },
                        { type: 'email', message: 'Введите корректный Email' },
                        { validator: emailAsyncValidate }
                    ]}
                >
                    <Input className="reg-input-text" />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    className='reg-label'
                    rules={[
                        { required: true, message: 'Введите пароль' },
                        { min: 6, message: 'Введите минимум 6 символов' }
                    ]}
                >
                    <Input.Password className="reg-input-text" placeholder="Пароль от 6 символов" />
                </Form.Item>

                <Form.Item
                    label="Повторите пароль"
                    name="password_confirmation"
                    className='reg-label'
                    dependencies={['password']}
                    rules={[
                        { required: true, message: 'Повторите пароль' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject('Пароли должны совпадать')
                            }
                        })
                    ]}
                >
                    <Input.Password className="reg-input-text" placeholder="Повторите предыдущий пароль" />
                </Form.Item>

                <Form.Item
                    label="Роль"
                    name="role"
                    className="reg-input-select"
                    rules={[{ required: true, message: 'Выберите роль' }]}
                >
                    <Select placeholder="Выбор роли">
                        {
                            roles.map(r => (
                                <Select.Option value={r.id} key={r.id}>{r.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                {
                    totalError
                        ? <div className="reg-user-total-error">
                            <p>{totalError}</p>
                        </div>
                        : null
                }


                <Form.Item className="user-reg-controls">
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Создать пользователя
                </Button>

                    <Button
                        htmlType="button"
                        onClick={onReset}
                    >
                        Очистить форму
                </Button>
                </Form.Item>
            </Form>
        </MenuLayout>
    )
}

export default RegisterUser