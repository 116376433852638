import React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons'
import './Navbar.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/Reducers/rootReducer'
import { logout } from '../../store/Actions/AuthActions'

interface NavbarProps extends RouteComponentProps {
	toggleMenu: () => void
}

const Navbar: React.FC<NavbarProps> = ({ history, toggleMenu }) => {

	const userName = useSelector<RootState>(state => state.app.user?.name)
	const dispatch = useDispatch()

	const logoutHandler = () => {
		dispatch(logout())
	}

	const menu = (
		<Menu>
			<Menu.Item key="0" onClick={logoutHandler}>
				<Link to="#">Выйти</Link>
			</Menu.Item>
		</Menu>
	)

	return (
		<nav className="navbar navbar-dark">
			<div className="container navbar-container">
				<button className="navbar-burger bf-btn" onClick={toggleMenu}>
					<UnorderedListOutlined/>
				</button>
				<Link to="/" className="navbar-brand">BF Notion</Link>
				<Dropdown overlay={menu} trigger={['click']}>
					<Link className="ant-dropdown-link navbar-dropdown" to="#" onClick={e => e.preventDefault()}>
						{ userName } <DownOutlined />
					</Link>
				</Dropdown>
			</div>
		</nav>
	)
}

export default withRouter(Navbar)