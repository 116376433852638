export const userStartRole = {
    "created_at": null,
    "id": 1,
    "info": {
        "cron": {
            "modify": true,
            "view": true
        },
        "mediaclub": {
            "view": true
        },
        "process": {
            "modify": true,
            "view": true
        },
        "profile": {
            "view": true
        },
        "report": {
            "duePyrus": true,
            "view": true
        },
        "settings": {
            "modify": true,
            "rules": true,
            "view": true
        },
        "testfile": {
            "view": true
        },
        "users": {
            "view": true
        }
    },
    "name": "Разработчик",
    "updated_at": null
}

export const userStart = {
    "link_logout": "",
    "link_start": "",
    "user": {
        "created_at": "",
        "email": "",
        "email_verified_at": null,
        "id": 3,
        "name": "",
        "updated_at": ""
    },
    "user_role": userStartRole
}