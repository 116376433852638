import React from 'react'
import './AllUsers.scss'
import MenuLayout from '../../../Components/Layouts/MenuLayout/MenuLayout'

interface AllUsersProps {}

const AllUsers: React.FC<AllUsersProps> = () => {

    return (
        <MenuLayout>
            <h1>AllUsers page</h1>
        </MenuLayout>
    )
}

export default AllUsers