import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import './UploadFile.scss'

type UploadFileProps = {
    name?: string
    onChange: (file: File | null) => void
}

const UploadFile: React.FC<UploadFileProps> = ({ name, onChange }) => {

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files
        if (fileList && fileList.length) return onChange(fileList[0])
        return onChange(null)
    }

    return (
        <label className="bf-upload-file">
            <input
                type="file"
                name={name}
                className="bf-upload-file__input"
                onChange={changeHandler}
            />
            <div className="ant-btn process-btn-primary">
                <PlusOutlined />
                <p className="bf-upload-file__text">Новый слайд</p>
            </div>
        </label>
    )
}

export default UploadFile