import React from 'react'
import './Roles.scss'
import MenuLayout from '../../../Components/Layouts/MenuLayout/MenuLayout'

interface RolesProps {}

const Roles: React.FC<RolesProps> = () => {

    return (
        <MenuLayout>
            <h1>Roles page</h1>
        </MenuLayout>
    )
}

export default Roles