
import axios from "axios"
import { Dispatch } from "react"
import { APP_USER_PROFILE, TOKEN_TYPE } from "../../config"
import { userStartRole } from "../../data/static"
import { routes } from "../../router/routes"
import { ReturnActions } from "../../types/actionsType"
import { UserType } from "../../types/responseTypes"
import { InitialAppData } from "../../types/routesType"
import { freshAccessToken } from "../../utils/cookie"
import { filterRoutes } from "../../utils/router"
import { APP_INITIAL_USER, APP_RESET, } from "./ActionTypes"
import { AllAuthActions } from "./AuthActions"


export const initialUser = () => {
    return async (dispatch: Dispatch<AllAppActions | AllAuthActions>) => {
        try {
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const res = await axios.get(APP_USER_PROFILE, option)

            console.log('initial user res', res);

            const data = res.data as UserType
            if (data) {
                const initialData: InitialAppData = {
                    user: data,
                    routes: filterRoutes(userStartRole.info, routes)
                }
                dispatch(appActions.initialUser(initialData))
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const appActions = {
    initialUser(initialData: InitialAppData) {
        return {
            type: APP_INITIAL_USER,
            payload: initialData
        } as const
    },
    resetAppData() {
        return {
            type: APP_RESET
        } as const
    }
}

export type AllAppActions = ReturnActions<typeof appActions>
