
import React from 'react'
import './App.scss'
import MainRouter from './router/MainRouter'

const App: React.FC<{}> = () => {
    return (
        <MainRouter />
    )
}

export default App;
