import React from 'react'
import './MainMenu.scss'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons';
import { Menu } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/Reducers/rootReducer'
import { renderMenu } from '../../../utils/menu'
import { UserRoutes } from '../../../types/routesType'
import { CSSTransition } from 'react-transition-group';

interface MainMenuProps extends RouteComponentProps {
    isShow: boolean
    hideMainMenu: () => void
}

const MainMenu: React.FC<MainMenuProps> = ({ isShow, location, hideMainMenu }) => {
    const filteredRoutes = useSelector<RootState>(state => state.app.routes) as UserRoutes

    const menuClassNames: string[] = ['main-menu']

    if (isShow) menuClassNames.push('main-menu-show')

    if (filteredRoutes){
        const menuLinks = renderMenu(filteredRoutes)
        const pathname = location.pathname
        const pathnameArr = pathname.split('/')

        const openedFirstSubMenu = '/' + pathnameArr[1]
        return (
            <>
                <CSSTransition
                    in={isShow}
                    timeout={400}
                    classNames={{
                        enter: 'bf-fade-in',
                        exit: 'bf-fade-out',
                    }}
                    unmountOnExit
                >
                    <div className="main-menu-overlay" onClick={hideMainMenu}></div>
                </CSSTransition>
                <Menu
                    theme="dark"
                    className={menuClassNames.join(' ')}
                    defaultSelectedKeys={[pathname]}
                    defaultOpenKeys={[openedFirstSubMenu]}
                    mode="inline"

                    onClick={hideMainMenu}
                >
                    <li className="main-menu-header">
                        <button className="main-menu-closer bf-btn" onClick={hideMainMenu}>
                            <LeftOutlined />
                        </button>
                    </li>
                    { menuLinks }
                </Menu>
            </>
        )
    }

    return null
}

export default withRouter(MainMenu)