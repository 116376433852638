import { Route } from 'react-router-dom';
import { RoleInfo } from '../types/rolesType'
import { UserRoutes, UserChildRoute, UserRoute } from '../types/routesType'

export const filterRoutes = function(roles: RoleInfo, routes: UserRoutes){
    return routes.filter(route => {
        if(roles[route.rule] && roles[route.rule]['view']){
            if(route.children)
                route.children = filterChildRoutes(roles, route.children, route.rule)
            return true
        }
        return false
    })
}

export const filterChildRoutes = (roles: RoleInfo, routes: UserChildRoute[], parentRule: string) => {
    return routes.filter(childRoute => {
        const rule = childRoute['rule']
        if (roles[parentRule] && roles[parentRule][rule]) {
            if (childRoute.children)
                childRoute.children = filterChildRoutes(roles, childRoute.children, parentRule)
            return true
        }
        return false
    })
}

export const renderRoutes = (filteredRoutes: UserRoutes): Array<JSX.Element | null> => {
    const JSXroutes: Array<JSX.Element | null> = []

    filteredRoutes.forEach((route, i) => {
        const routePath = route.path
        renderRoute(route, i, routePath)
    })

    function renderRoute(route: UserRoute | UserChildRoute, i: number, parentPath = '/404') {
        if (route.children && route.children.length > 0) {
            route.children.forEach((childrenRoute, i) => {
                const childPath = childrenRoute.path
                renderRoute(childrenRoute, i , `${parentPath}/${childPath}`)
            })
        }

        if (route.component) {
            JSXroutes.push(<Route path={parentPath} component={route.component} exact key={i + Math.random()}/>)
        }
        JSXroutes.push(null)
    }
    return JSXroutes
}