import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from '../config';
import { AuthResponse } from '../types/responseTypes'

export function getTokensFromSession() {
    return {
        access_token: window.sessionStorage.getItem(ACCESS_TOKEN_COOKIE_NAME),
        refresh_token: window.sessionStorage.getItem(REFRESH_TOKEN_COOKIE_NAME)
    }
}

export function setTokensToSession(data: AuthResponse) {
    window.sessionStorage.setItem(ACCESS_TOKEN_COOKIE_NAME, data.access_token)
    window.sessionStorage.setItem(REFRESH_TOKEN_COOKIE_NAME, data.refresh_token)
    setTimeout(() => {
        window.sessionStorage.removeItem(ACCESS_TOKEN_COOKIE_NAME)
    }, (data.expires_in * 1000 - 30000))
    setTimeout(() => {
        window.sessionStorage.removeItem(REFRESH_TOKEN_COOKIE_NAME)
    }, (data.refresh_expires_in * 1000 - 30000))
}

export function deleteTokensFromSession() {
    window.sessionStorage.removeItem(ACCESS_TOKEN_COOKIE_NAME)
    window.sessionStorage.removeItem(REFRESH_TOKEN_COOKIE_NAME)
}