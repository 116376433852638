import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { Form, Input, Card, InputNumber, Button, Popconfirm, notification } from 'antd'
import { CloseCircleOutlined, PlusOutlined, PlusCircleOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import './NewProcess.scss'
import axios from 'axios'
import { OpenForms } from '../../types/responseTypes'
import { getProcessForm } from '../../utils/data'
import { ProcessForm } from '../../types/dataType'
import { freshAccessToken } from '../../utils/cookie'
import { PYRUS_FORM, TOKEN_TYPE } from '../../config'

type NewProcessProps = {}

const NewProcess: React.FC<NewProcessProps> = () => {
    // 637652  -- существующий id
    const [formName, setFormName] = useState<string>('')
    const [forms, setForms] = useState<ProcessForm[]>([getProcessForm()])
    const [openForms, setOpenForms] = useState<OpenForms | null>(null)

    useEffect(() => {
        async function getPyrusForms() {
            try {
                const access = await freshAccessToken()
                const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
                const response = await axios.get(PYRUS_FORM, option)
                if (response.status === 200) {
                    const data: OpenForms = response.data
                    setOpenForms(data)
                } else {
                    console.log('Invalid Pyrus response, status', response.status)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getPyrusForms()
    }, [])

    const processNameHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFormName(e.target.value)
    }

    const addForm = () => {
        const newForms = [...forms]
        newForms.push({ ...getProcessForm() })
        setForms(newForms)
    }

    const changeForm = (index: number, formData: ProcessForm) => {
        const newForms = forms.map((form, i) => {
            if (i === index) {
                form = { ...form, ...formData }
            }
            return form
        })
        setForms(newForms)
    }

    const searchForm = (index: number) => {
        if (openForms) {
            const el = forms[index]
            const form = openForms[el.id]

            if (form) {
                const changeData = {
                    state: 'done',
                    title: form
                }
                changeForm(index, { ...el, ...changeData })
                notification.success({
                    message: 'Форма добавлена',
                    description: `ID: ${el.id} Название: ${form}`,
                    icon: <CheckCircleOutlined style={{ color: '#108ee9', fontSize: '26px' }} />,
                })
            } else {
                notification.error({
                    message: 'Предупреждение',
                    description: 'Формы не существует или нет доступа',
                    icon: <WarningOutlined style={{ color: '#df0303', fontSize: '26px' }} />,
                })
            }
        }
    }

    const changeIdHandler = (form: ProcessForm, index: number, value: number | string | null | undefined) => {
        if (typeof value === 'number') {
            changeForm(index, { ...form, id: value })
        } else if (value === '') {
            changeForm(index, { ...form, id: 0 })
        } else {
            console.log('Change Id Error! Must be number')
        }
    }

    const submitProcess = () => {
        console.log({
            name: formName,
            forms
        })
    }

    const resetForm = () => {
        setFormName('')
        setForms([{ ...getProcessForm() }])
    }

    return (
        <Form
            name="new-proc"
            initialValues={{ remember: true }}
            className="process-form"
        >
            <Form.Item
                label="Название процесса"
                name="process_name"
                className="new-pocess-name"
            >
                <Input value={formName} onChange={processNameHandler} />
            </Form.Item>

            {
                forms.map((form, idx) => {
                    return (
                        <Card title="Новая форма" className="new-process-card" extra={<CloseCircleOutlined />} key={form._key}>
                            <p>ID формы Pyrus</p>
                            <InputNumber
                                className="new-process-number"
                                value={form.id}
                                onChange={value => changeIdHandler(form, idx, value)}
                            />
                            <Button
                                className="process-btn-primary"
                                icon={<PlusOutlined />}
                                onClick={() => searchForm(idx)}
                            >
                                Добавить
                            </Button>
                        </Card>
                    )
                })
            }

            <PlusCircleOutlined className="new-process-add-card" onClick={addForm} />

            <div className="process-submit-field">
                <Button className="process-btn-primary" onClick={submitProcess}>Сохранить</Button>
                <Popconfirm placement="bottom" title={'Вы уверены?'} onConfirm={resetForm} okText="Да" cancelText="Отмена">
                    <Button className="process-btn-white">Очистить</Button>
                </Popconfirm>
            </div>
        </Form>
    )
}

export default NewProcess