import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout'

const NotFound: React.FC<{}> = () => {
    return (
        <MenuLayout>
            <Result
                status="404"
                title="404"
                subTitle="Страница не найдена"
                extra={
                    <Button type="primary">
                        <Link to='/'> На главную </Link>
                    </Button>
                }
            />
        </MenuLayout>
    )
}

export default NotFound