import React, { useState } from 'react'
import './UploadPage.scss'
import { Upload, Button } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout';
import { freshAccessToken } from '../../utils/cookie';
import { DROP_UPLOAD, TOKEN_TYPE, GOOGLE_UPLOAD } from '../../config';

interface UploadProps { }

const UploadPage: React.FC<UploadProps> = () => {

    const [firstLoading, setFirstLoading] = useState<boolean>(false)
    const [listFilePreview, setListFilePreview] = useState<UploadFile<any>[]>([])
    const [listFile, setListFile] = useState<File[]>([])

    const [secondLoading, setSecondLoading] = useState<boolean>(false)
    const [driveFilePreview, setDriveFilePreview] = useState<UploadFile<any>[]>([])
    const [driveFile, setDriveFile] = useState<File[]>([])

    const [singleFile, setSingleFile] = useState<File | null>(null)

    const firstHandleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log('handleChange ', info)
        const originalFile = info.file.originFileObj
        if (info.file.status === 'uploading') {
            setFirstLoading(true)
            return
        }

        // if (info.file.status === 'done' && Object.getPrototypeOf(originalFile) === File) {
        if (originalFile instanceof File) {
            const loadedListFile = [...listFile]
            loadedListFile.push(originalFile)
            setFirstLoading(false)
            setListFilePreview([...info.fileList])
            setListFile(loadedListFile)
        } else {
            console.log('originalFile is not a File')
        }
    }

    const secondHandleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        const originalFile = info.file.originFileObj
        console.log('handleChange ', info)
        if (info.file.status === 'uploading') {
            setSecondLoading(true)
            return
        }
        // if (info.file.status === 'done' && Object.getPrototypeOf(originalFile) === File) {
        if (originalFile instanceof File) {
            const loadedListFile = [...driveFile]
            loadedListFile.push(originalFile)
            setSecondLoading(false)
            setDriveFilePreview([...info.fileList])
            setDriveFile(loadedListFile)
        } else {
            console.log('originalFile is not a File')
        }
    }

    const onChangeSingle = (info: UploadChangeParam<UploadFile<any>>) => {
        const originalFile = info.file.originFileObj
        if (originalFile instanceof File) {
            setSingleFile(originalFile)
        } else {
            console.log('originalFile is not a File')
        }
    }

    const onPreview = async (file: UploadFile<any>) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as Blob);
                reader.onload = () => resolve(reader.result as string)
            });
        }
        const image = new Image()
        image.src = src + ''
        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const axiosFiles = async () => {
        try {
            const dataForm = new FormData()
            dataForm.append('file', listFile[0])
            console.log('to dropbox', dataForm.getAll('file'))

            const access = await freshAccessToken()
            const option = {
                headers:
                {
                    'Authorization': `${TOKEN_TYPE} ${access}`,
                    'Content-Type': "multipart/form-data"
                }
            }

            const response = await axios.post(DROP_UPLOAD, dataForm, option)
            if (response.status === 200) {
                console.log(response)
            } else {
                console.log('Error response, status:', response.status)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const googleFiles = async () => {
        try {
            const dataForm = new FormData()
            dataForm.append('file', driveFile[0])
            console.log('to drive', dataForm);

            const access = await freshAccessToken()
            const option = {
                headers:
                {
                    'Authorization': `${TOKEN_TYPE} ${access}`,
                    'Content-Type': "multipart/form-data"
                }
            }

            const response = await axios.post(GOOGLE_UPLOAD, dataForm, option)
            if (response.status === 200) {
                console.log(response)
            } else {
                console.log('Error response, status:', response.status)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const testSubmit = async () => {
        try {
            if (singleFile) {
                const dataForm = new FormData()
                dataForm.append('file', singleFile)

                const access = await freshAccessToken()
                const option = {
                    headers:
                    {
                        'Authorization': `${TOKEN_TYPE} ${access}`,
                        'Content-Type': "multipart/form-data"
                    }
                }

                const response = await axios.post(GOOGLE_UPLOAD, dataForm, option)
                if (response.status === 200) {
                    console.log(response)
                } else {
                    console.log('Error response, status:', response.status)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const clickFiles = () => {
        axiosFiles()
        googleFiles()
    }

    const logFiles = () => {
        console.log('listFile', listFile)
        console.log('driveFile', driveFile)
    }

    return (
        <MenuLayout>
            <div className="upload-wrapper">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={listFilePreview}
                    onChange={firstHandleChange}
                    onPreview={onPreview}
                    action={DROP_UPLOAD}
                // action="/"   !!!Change this to http://127.0.0.1:8000/drop/upload
                >
                    <div>
                        {firstLoading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div>Upload</div>
                    </div>
                </Upload>
            </div>

            <div className="upload-wrapper">
                <Upload
                    name="avatar2"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={driveFilePreview}
                    onChange={secondHandleChange}
                    onRemove={() => false}
                    onPreview={onPreview}
                    action={GOOGLE_UPLOAD}
                // action="/"   !!!Change this to http://127.0.0.1:8000/google/upload
                >
                    <div>
                        {secondLoading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div>Upload</div>
                    </div>
                </Upload>
            </div>


            <Upload
                multiple={false}
                action={DROP_UPLOAD}
                onChange={onChangeSingle}
            >
                <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>

            <div className="upload-controls">
                <Button className="process-btn-primary" onClick={clickFiles}>Отправить</Button>
                <Button className="process-btn-primary" onClick={logFiles}>Проверка</Button>
                <Button className="process-btn-primary" onClick={testSubmit}>Тест</Button>
            </div>
        </MenuLayout>
    )
}

export default UploadPage