import { combineReducers } from "redux";
import { appReducer } from "./AppReducer";
import { authReducer } from "./AuthReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
})

export type RootState = ReturnType<typeof rootReducer>
