import React, { useEffect, useState } from 'react'
import { InputNumber, notification, Popconfirm } from 'antd'
import './Media.scss'
import UploadFile from '../../Components/Form/UploadFile/UploadFile'
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import axios from 'axios'
import { MediaScreensResponse, ScreenItem } from '../../types/responseTypes'
import MediaItems from '../../Components/media/MediaItems/MediaItems'
import { Spin } from 'antd'
import { LoadingOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout'
import { MEDIA_GET_ITEMS, TOKEN_TYPE, MEDIA_ADD_ITEM, MEDIA_DELETE_ITEM, MEDIA_SEND_DATA } from '../../config'
import { freshAccessToken } from '../../utils/cookie'

interface MediaProps { }

const Media: React.FC<MediaProps> = () => {

    const [relevantItems, setRelevantItems] = useState<ScreenItem[]>([])
    const [deletedItems, setDeletedItems] = useState<ScreenItem[]>([])
    const [sliderInterval, setSliderInterval] = useState<number>(0)
    const [isChange, setChange] = useState<boolean>(false)

    const [loaderTitle, setLoaderTitle] = useState<string>('')
    const [isUploadingItem, setUploadingItem] = useState<boolean>(false)

    /*
        *** Computed ***
    */
    const allItems = () => [...relevantItems, ...deletedItems]

    /*
        *** Helpers ***
    */
    const resetLoader = (e?: Error, title?: string) => {
        setLoaderTitle('')
        setUploadingItem(false)
        if (e) {
            notification.error({
                message: title,
                description: e.message,
                icon: <WarningOutlined style={{ color: '#df0303', fontSize: '26px' }} />,
                placement: 'bottomRight',
            })
        }
    }

    const updateItems = (data: MediaScreensResponse) => {
        const hasChange = data.files.some(f => f.catalog === 'p_del' || f.catalog === 'p_new')
        setChange(hasChange)
        setRelevantItems(data.files.filter(f => f.catalog !== 'p_del'))
        setDeletedItems(data.files.filter(it => it.catalog === 'p_del'))
    }

    /*
        *** Handlers ***
    */
    const changeIntervalHandler = (value: string | number | null | undefined) => {
        if (value && +value) {
            setSliderInterval(+value)
            setChange(true)
        }
    }

    const onDragEnd = (result: DropResult) => {
        console.log('drag res', result)
        if (result.destination) {
            const newItems = [...relevantItems] as ScreenItem[]
            const fromIndex = result.source.index
            const toIndex = result.destination?.index
            const [droppedItem] = newItems.splice(fromIndex, 1)
            newItems.splice(toIndex, 0, droppedItem)
            setRelevantItems(newItems)
        }
    }

    const onSubmit = () => {
        console.log('submitted form, data:', {
            files: allItems(),
            interval: sliderInterval
        })
        sendData()
    }

    /*
        *** Async ***
    */
    const sendData = async () => {
        try {
            setLoaderTitle('Сохранение изменений')
            setUploadingItem(true)
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const body = {
                files: allItems(),
                interval: sliderInterval,
                placement: 'bottomRight',
            }
            const res = await axios.post(MEDIA_SEND_DATA, body, option)
            const data = res.data as MediaScreensResponse
            console.log('sendData response', res)
            if (data) {
                updateItems(data)
                setUploadingItem(false)
                notification.success({
                    message: 'Данные успешно сохранены',
                    icon: <CheckCircleOutlined style={{ color: '#108ee9', fontSize: '26px' }} />,
                    placement: 'bottomRight',
                })
            }
            resetLoader()
        } catch (e) {
            console.log(e)
            resetLoader(e as Error, 'Ошибка сохранения изменений')
        }
    }

    const addItemHandler = async (file: File | null) => {
        try {
            if (file) {
                setLoaderTitle('Отправка изображения')
                setUploadingItem(true)
                const dataForm = new FormData()
                dataForm.set('file', file)
                dataForm.set('sort', allItems().length + '')
                const access = await freshAccessToken()
                const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
                const res = await axios.post(MEDIA_ADD_ITEM, dataForm, option)
                console.log('add resp', res);
                const data = res.data as MediaScreensResponse
                if (data) {
                    updateItems(data)
                    setUploadingItem(false)
                }
                resetLoader()
            }
        } catch (e) {
            console.log(e)
            resetLoader(e as Error, 'Ошибка добавления')
        }
    }

    const deleteItemHandler = async (item: ScreenItem) => {
        try {
            setLoaderTitle('Удаление')
            setUploadingItem(true)
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const res = await axios.post(MEDIA_DELETE_ITEM, { id: item.id }, option)
            const data = res.data as MediaScreensResponse
            console.log('delete item response', res)

            if (data) {
                updateItems(data)
            }
            resetLoader()
        } catch (e) {
            console.log(e)
            resetLoader(e as Error, 'Ошибка удаления')
        }
    }

    const initialData = async () => {
        try {
            setLoaderTitle('Загрузка данных')
            setUploadingItem(true)
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const res = await axios.get(MEDIA_GET_ITEMS, option)

            console.log('get media items', res)
            const data = res.data as MediaScreensResponse
            if (data) {
                updateItems(data)
                setSliderInterval(+data.interval)
            }
            resetLoader()
        } catch (e) {
            console.log(e)
            resetLoader(e as Error, 'Ошибка при получении данных')
        }
    }

    /*
        *** Hooks ***
    */
    useEffect(() => {
        initialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MenuLayout>
            <DragDropContext onDragEnd={onDragEnd}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="media-interval">
                        <p className="media-interval__title">Интервал перелистывания между слайдами <b>(сек)</b></p>
                        <InputNumber
                            size="large"
                            min={1}
                            value={sliderInterval}
                            className="media-interval__input"
                            onChange={changeIntervalHandler}
                        />
                    </div>
                    <div className="media-slides">
                        <p className="media-slides__title">Порядок слайдов</p>
                        <div className="media-slides__container">

                            <div className="media-slides__table">
                                <div className="media-slides__table-scroll">
                                    <div className="media-slides__header">
                                        <div className="media-slides__header-row col-3">
                                            <p>Изображение</p>
                                        </div>
                                        <div className="media-slides__header-row col-6">
                                            <p>Название</p>
                                        </div>
                                        <div className="media-slides__header-row col-3">
                                            <p>Действия</p>
                                        </div>
                                    </div>
                                    <MediaItems
                                        items={relevantItems}
                                        onDeleteItem={deleteItemHandler}
                                    />
                                </div>
                            </div>

                            <div className="media-slides__controls">
                                <UploadFile onChange={addItemHandler} />

                                {
                                    isUploadingItem
                                        ? <div className="media-slides__loader">
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 26 }} spin />} />
                                            {loaderTitle}
                                        </div>
                                        : null
                                }

                                {
                                    isChange
                                        ? <p className="media-slides__controls-message">Есть изменения которые не были сохранены</p>
                                        : null
                                }

                                <Popconfirm
                                    placement="bottom"
                                    title='Внесенные изменения будут Сохранены! Продолжить?'
                                    okText="Да"
                                    cancelText="Отмена"
                                    onConfirm={onSubmit}
                                >
                                    <button className="ant-btn process-btn-primary">
                                        Сохранить
                            </button>
                                </Popconfirm>
                            </div>

                        </div>
                    </div>
                </form>
            </DragDropContext>
        </MenuLayout>
    )
}

export default Media