import React from 'react'
import ImgWithLoader from '../ImgWithLoader/ImgWithLoader'
import './SliderItem.scss'
import { ScreenItem } from '../../types/responseTypes'

type SliderItemProps = {
    item: ScreenItem
    onDeleteItem: (item: ScreenItem) => void
}

const SliderItem = React.forwardRef<HTMLDivElement, SliderItemProps>((props, ref) => {
    const { item, onDeleteItem, ...dndProps } = props

    return (
        <div className="media-slides-item" ref={ref} {...dndProps}>
            <div className="media-slides-item__img col-3">
                <ImgWithLoader
                    src={item.img}
                    alt="slide-img"
                    wrapperClasses={['justify-start']}
                    loaderColor="#1890ff"
                    loaderSize="40px"
                />
            </div>
            <div className="media-slides-item__name col-6">
                <p>{item.name}</p>
            </div>
            <div className="media-slides-item__actoins col-3">
                <p
                    className="media-slides-item__delete"
                    onClick={() => onDeleteItem(item)}
                >
                    Удалить
                </p>
            </div>
        </div>
    )
})

export default SliderItem