import React, { useEffect, useState } from 'react'
import './DataProcess.scss'
import { Button, Form, Popconfirm, Select } from 'antd'
import { PyrusGetData } from '../../types/responseTypes'
import axios from 'axios'
import { LabeledValue } from 'antd/lib/select'
import { freshAccessToken } from '../../utils/cookie'
import { TOKEN_TYPE, PYRUS_GET_DATA, PYRUS_SEND_DATA } from '../../config'
const { Option } = Select


type DataProcessProps = {}

const DataProcess: React.FC<DataProcessProps> = () => {

    const [processes, setProcesses] = useState<PyrusGetData>([])
    const [selected, setSelected] = useState<PyrusGetData>([])

    useEffect(() => {
        async function getPyrusData() {
            try {
                const access = await freshAccessToken()
                const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
                const res = await axios.get(PYRUS_GET_DATA, option)
                console.log('Data proccess res', res);
                if (res.status === 200) {
                    setProcesses(res.data)
                } else {
                    console.log(`Invalid response DataProcess, status ${res.status}`)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getPyrusData()
    }, [])

    const addSelected = (optionValue: string | number | LabeledValue) => {
        if (typeof optionValue === 'number') {
            const selectedOption = processes?.find(option => option.id === optionValue)
            if (selectedOption && selected) {
                const newSelected = [...selected]
                newSelected.push(selectedOption)
                setSelected(newSelected)
            }
        }
    }

    const removeSelected = (optionValue: string | number | LabeledValue) => {
        if (typeof optionValue === 'number') {
            const newSelected = selected.filter(option => option.id !== optionValue)
            setSelected(newSelected)
        }
    }

    const submitData = async () => {
        try {
            console.log('Selected', selected)
            const access = await freshAccessToken()
            const option = { headers: { 'Authorization': `${TOKEN_TYPE} ${access}` } }
            const response = await axios.post(PYRUS_SEND_DATA, {
                type: selected
            }, option)
            console.log('data process response', response)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Form
            name="data-process"
            className="process-form"
        >
            <Select
                showSearch
                placeholder="Выберите процессы"
                className="process-select data-process-select"
                mode='multiple'
                value={selected.map(o => o.id)}
                onSelect={addSelected}
                onDeselect={removeSelected}
                filterOption={(filterValue, option) => {
                    return option?.children.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
                }}
            >
                {
                    processes?.map((process) => {
                        return (
                            <Option value={process.id} key={process.id}>
                                {process.name}
                            </Option>
                        )
                    })
                }
            </Select>

            <div className="process-submit-field data-process-controls">
                <Button className="process-btn-primary" onClick={submitData}>Сохранить</Button>
                <Popconfirm placement="bottom" title={'Вы уверены?'} onConfirm={() => setSelected([])} okText="Да" cancelText="Отмена">
                    <Button className="process-btn-white">Очистить</Button>
                </Popconfirm>
            </div>
        </Form>
    )
}

export default DataProcess