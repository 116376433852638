import React from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import './Cron.scss'
import { Tabs } from 'antd'
import MenuLayout from '../../Components/Layouts/MenuLayout/MenuLayout'
const { TabPane } = Tabs

interface CronProps extends RouteComponentProps { }

const Cron: React.FC<CronProps> = () => {

    return (
        <MenuLayout>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Новая задача Cron" key="1"></TabPane>
                <TabPane tab="Все задачи Cron" key="2"></TabPane>
            </Tabs>
        </MenuLayout>
    )
}

export default withRouter(Cron)