import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './FrameReporting.scss'
import MenuLayout from '../../../Components/Layouts/MenuLayout/MenuLayout'

interface FrameReportingProps extends RouteComponentProps { }

const FrameReporting: React.FC<FrameReportingProps> = ({ history }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [url, setUrl] = useState<string>('')

    useEffect(() => {
        if (history.location.pathname.startsWith('/reporting')) {
            setUrl('https://datastudio.google.com/embed/reporting/6c4a13b3-24a1-4f6b-af02-9c3482a599c7/page/s949')
        }
        if (frameRed.current) {
            frameRed.current.onload = () => {
                setLoading(false)
            }
            frameRed.current.onerror = () => {
                console.error('Error load Iframe content!!!')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const frameRed = useRef<HTMLIFrameElement>(null)

    return (
        <MenuLayout>
            <div className="reporting">
                <div className="reporting__content">
                    {
                        loading
                            ? <Spin className="reporting__loader" indicator={<LoadingOutlined spin />} />
                            : null
                    }
                    <iframe src={url} className="reporting__frame" frameBorder="0" ref={frameRed} title="report-frame"></iframe>
                </div>
            </div>
        </MenuLayout>
    )
}

export default withRouter(FrameReporting)