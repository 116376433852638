import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/Reducers/rootReducer'
import { initialUser } from '../store/Actions/AppActions'
import NotFound from '../Pages/404/NotFound'
import Loader from '../Components/Loader/Loader'
import CaruselMedia from '../Pages/CaruselMedia/CaruselMedia'
import AuthPage from '../Pages/AuthPage/AuthPage'
import { checkAuth } from '../store/Actions/AuthActions'
import Profile from '../Pages/Profile/Profile'
import Process from '../Pages/Process/Process'
import Media from '../Pages/Media/Media'
import UploadPage from '../Pages/UploadPage/UploadPage'
import FrameReporting from '../Pages/reporting/FrameReporting/FrameReporting'
import Cron from '../Pages/Cron/Cron'
import AllUsers from '../Pages/users/AllUsers/AllUsers'
import RegisterUser from '../Pages/users/RegisterUser/RegisterUser'
import ProfileSetting from '../Pages/settings/ProfileSetting/ProfileSetting'
import Roles from '../Pages/settings/Roles/Roles'
import { UserRoles } from '../types/responseTypes'

type MainRouterProps = {}

const MainRouter: React.FC<MainRouterProps> = () => {
    const isAuth = useSelector<RootState>(state => state.auth.isAuth) as boolean | null
    const userRoles = useSelector<RootState>(state => state.app.user?.roles) as UserRoles | undefined
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(checkAuth())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isAuth) {
            dispatch(initialUser())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    const hasAccess = userRoles?.includes('Admin')

    if (isAuth === false) {
        return (
            <Switch>
                <Route path="/auth" exact component={AuthPage} />
                <Route path="/media/:catalog" exact component={CaruselMedia} />
                <Redirect to="/auth" />
            </Switch>
        )
    }

    if (isAuth && hasAccess) {
        return (
            <Switch>
                <Redirect from="/auth" to="/" />
                {/* {
                    renderRoutes(filteredRoutes)
                } */}

                <Route path="/" exact component={Profile} />
                <Route path="/bp" exact component={Process} />
                <Route path="/media" exact component={Media} />
                <Route path="/media/:catalog" exact component={CaruselMedia} />
                <Route path="/file" exact component={UploadPage} />
                <Route path="/reporting" exact component={FrameReporting} />
                <Route path="/tasks" exact component={Cron} />
                <Route path="/users" exact component={AllUsers} />
                <Route path="/users/new" exact component={RegisterUser} />
                <Route path="/settings" exact component={ProfileSetting} />
                <Route path="/settings/rules" exact component={Roles} />

                <Route path="/404" component={NotFound} />
                <Redirect to="/404" />
            </Switch>
        )
    }

    return <Loader classes={['initialLoader']} />
}

export default MainRouter